<template>
  <header class="header">
    <BaseNav :firebaseTexts="mainPage[0] ? mainPage[0] : ''"/>
  </header>
  <router-view v-slot="{Component}" v-if="mainPage[0]" :firebaseTexts="mainPage[0] ? mainPage[0] : ''" :policy="policy[0] ? policy[0] : ''">
    <transition name="scale" mode="out-in">
      <component :is="Component" :key="$route.path"></component>
    </transition>
  </router-view>
  <ShortPolicy v-if="!acceptCookie && policy[0]" :policyText="policy[0].short_policy ? policy[0].short_policy : ''"/>
  <FooterBase v-if="mainPage[0]" :firebaseTexts="mainPage[0]" :policy="policy[0] ? policy[0] : ''"/>
</template>

<script>
import getMainPage from './composables/getMainPage'
import getPolicy from './composables/getPolicy'
import { defineAsyncComponent, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  name: 'App',
  components: {
    BaseNav: defineAsyncComponent(() =>
      import('./components/BaseNav.vue')
    ),
    FooterBase: defineAsyncComponent(() =>
      import('./components/FooterBase.vue')
    ),
    ShortPolicy: defineAsyncComponent(() =>
      import('./components/ShortPolicy.vue')
    )
  },
  setup () {
    const { mainPage, errorMainPage, loadMainPage } = getMainPage()
    const { policy, errorPolicy, loadPolicy } = getPolicy()
    const store = useStore()
    const route = useRoute()

    const acceptCookie = computed(() => {
      return store.getters.getCookieStatus
    })

    watch(route, (currentValue, oldValue) => {
      if (window.location.hash) {
        const scrollInterv = setInterval(() => {
          const el = document.getElementById(window.location.hash.replace('#', ''))
          if (el) {
            clearInterval(scrollInterv)
            setTimeout(() => {
              el.scrollIntoView()
            }, 500)
          }
        }, 50)
      }
    })

    loadMainPage()
    loadPolicy()

    return { mainPage, errorMainPage, policy, errorPolicy, acceptCookie }
  }
}
</script>

<style lang="scss">
.scale-enter-active,
.scale-leave-active {
  transition: all 0.3s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
html {
  font-size: 14px;
  scroll-behavior: smooth;
  #mc-embedded-subscribe-form {
    .embeddedForm {
      background: #333;
      position: fixed;
      top: -100%;
    }
  }
}
@media screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }
}
body {
  background: #854c2f;
  font-family: 'Roboto', sans-serif;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.header, .footer {
  z-index: 1;
}
#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  main {
    flex: 1;
  }
}
.siteWrap {
  max-width: 900px;
  margin: 0 auto;
}
ul {
  list-style-type: none;
}
//////////////////////////////////////////
/* to get pixelated images (nearest-neighbor filter) on all  browsers */
.rpgui-pixelated {
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -webkit-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated; }

/* unselectable text */
.rpgui-noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* center things */
.rpgui-center {
  text-align: center;
  align-content: center; }

/* rotate object 90 degrees */
.rpgui-rotate-90 {
  /* rotate 90 degrees */
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  /* rotate from center-left side */
  -ms-transform-origin: 0% 50%;
  /* IE 9 */
  -webkit-transform-origin: 0% 50%;
  /* Chrome, Safari, Opera */
  transform-origin: 0% 50%; }

/**
* Styling for buttons
*/
/* button style */
.rpgui-button {
  /* hide button default stuff */
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
  /* background */
  background: url("./assets/img/button.png") no-repeat no-repeat;
  background-clip: padding-box;
  background-origin: padding-box;
  background-position: center;
  background-size: 100% 100%;
  /* font size */
  font-size: 1.0rem;
  /* default size and display */
  max-width: 100%;
  min-width: 140px;
  height: 60px;
  display: inline-block;
  /* padding */
  padding-left: 35px;
  padding-right: 35px; }

/* button hover */
.rpgui-button.hover,
.rpgui-button:hover {
  background-image: url("./assets/img/button-hover.png"); }

/* button clicked */
.rpgui-button.down,
.rpgui-button:active {
  background-image: url("./assets/img/button-down.png"); }

/* golden button stuff */
.rpgui-button.golden p {
  display: inline-block; }

/* golden button style */
.rpgui-button.golden {
  /* hide button default stuff */
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
  /* background */
  background: url("./assets/img/button-golden.png") no-repeat no-repeat;
  background-clip: padding-box;
  background-origin: padding-box;
  background-position: center;
  background-size: 100% 80%;
  /* default size and display */
  max-width: 100%;
  min-width: 140px;
  height: 60px;
  display: inline-block;
  /* padding */
  padding-top: 5px;
  padding-left: 35px;
  padding-right: 35px;
  overflow: visible; }

/* button hover */
.rpgui-button.golden.hover,
.rpgui-button.golden:hover {
  background-image: url("./assets/img/button-golden-hover.png"); }

/* button clicked */
.rpgui-button.golden.down,
.rpgui-button.golden:active {
  background-image: url("./assets/img/button-golden-down.png"); }

.rpgui-button.golden:before {
  white-space: nowrap;
  display: inline-block;
  content: "";
  width: 34px;
  display: block;
  height: 110%;
  background: transparent url("./assets/img/button-golden-left.png") no-repeat right center;
  background-size: 100% 100%;
  margin: 0 0 0 0;
  left: 0px;
  float: left;
  margin-left: -46px;
  margin-top: -5%; }

.rpgui-button.golden:after {
  white-space: nowrap;
  display: block;
  content: "";
  width: 34px;
  height: 110%;
  background: transparent url("./assets/img/button-golden-right.png") no-repeat left center;
  background-size: 100% 100%;
  margin: 0 0 0 0;
  right: 0px;
  float: right;
  margin-right: -46px;
  margin-top: -5%; }

/*
.rpgui-button.golden:hover:before {
    background-image: url('img/button-golden-left-hover.png');
}
.rpgui-button.golden:hover:after {
    background-image: url('img/button-golden-right-hover.png');
}
*/
/**
* style for checkboxes
*/
/* basic checkbox */
.rpgui-content input[type=checkbox].rpgui-checkbox {
  display: none; }

.rpgui-content input[type=checkbox].rpgui-checkbox + label {
  background: url("./assets/img/checkbox-off.png") no-repeat;
  line-height: 24px;
  display: inline-block;
  background-size: auto 100%;
  padding-left: 34px;
  height: 24px;
  margin-top: 10px;
  margin-bottom: 10px; }

.rpgui-content input[type=checkbox].rpgui-checkbox:checked + label {
  background: url("./assets/img/checkbox-on.png") no-repeat;
  line-height: 24px;
  display: inline-block;
  background-size: auto 100%;
  padding-left: 34px;
  height: 24px; }

/* golden checkbox */
.rpgui-content input[type=checkbox].rpgui-checkbox.golden + label {
  background: url("./assets/img/checkbox-golden-off.png") no-repeat;
  background-size: auto 100%; }

.rpgui-content input[type=checkbox].rpgui-checkbox.golden:checked + label {
  background: url("./assets/img/checkbox-golden-on.png") no-repeat;
  background-size: auto 100%; }

/**
* global content styling
*/
/* game div with background image*/
.rpgui-content {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  // position: fixed;
  // overflow: hidden;
  font-size: 0.8rem; }

/* general rules to apply on anything inside the content */
.rpgui-content * {
  /* remove outline effect for input elements etc */
  outline: none;
  /* prevent dragging */
  user-drag: none;
  -webkit-user-drag: none;
  /* prevent text selecting */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  /* pixelated enlargement filter (to keep the pixel-art style when enlarging pictures) */
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -webkit-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  /* default font */
  font-family: 'Press Start 2P', cursive;
}

/**
* customized divs (containers) and framed objects (background and frame image).
*/
/* game div without background image*/
.rpgui-container {
  /* position style and default z */
  position: fixed;
  z-index: 10;
  overflow: show; }

/* game div with background image*/
.rpgui-container.framed {
  /* border */
  border-style: solid;
  border-image-source: url("./assets/img/border-image.png");
  border-image-repeat: repeat;
  border-image-slice: 6 6 6 6;
  border-image-width: 18px;
  border-width: 15px;
  padding: 12px;
  /* internal border */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* background */
  background: url("./assets/img/background-image.png") repeat repeat;
  background-clip: padding-box;
  background-origin: padding-box;
  background-position: center; }

/* game div with golden background image*/
.rpgui-container.framed-golden {
  /* border */
  border-style: solid;
  border-image-source: url("./assets/img/border-image-golden.png");
  border-image-repeat: repeat;
  border-image-slice: 4 4 4 4;
  border-image-width: 18px;
  border-width: 15px;
  padding: 12px;
  /* internal border */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* background */
  background: url("./assets/img/background-image-golden.png") repeat repeat;
  background-clip: padding-box;
  background-origin: padding-box;
  background-position: center; }

/* game div with golden2 background image*/
.rpgui-container.framed-golden-2 {
  /* border */
  border-style: solid;
  border-image-source: url("./assets/img/border-image-golden2.png");
  border-image-repeat: repeat;
  border-image-slice: 8 8 8 8;
  border-image-width: 18px;
  border-width: 15px;
  padding: 12px;
  /* internal border */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* background */
  background: url("./assets/img/background-image-golden2.png") repeat repeat;
  background-clip: padding-box;
  background-origin: padding-box;
  background-position: center; }

/* game div with soft grey background image*/
.rpgui-container.framed-grey {
  position: relative;
  /* border */
  border-style: solid;
  border-image-source: url("./assets/img/border-image-grey.png");
  border-image-repeat: repeat;
  border-image-slice: 3 3 3 3;
  border-image-width: 7px;
  border-width: 7px;
  padding: 12px;
  /* internal border */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* background */
  background: url("./assets/img/background-image-grey.png") repeat repeat;
  background-clip: padding-box;
  background-origin: padding-box;
  background-position: center; }

/**
* different cursor graphics
*/
/* default cursor important */
/* this rule is for when you specifically request this cursor class */
.rpgui-cursor-default {
  cursor: url("./assets/img/cursor/default.png"), auto !important; }

/* default cursor, not important, for all elements without any other rule. */
.rpgui-content,
label {
  cursor: url("./assets/img/cursor/default.png"), auto; }

/* pointer / hand cursor important */
/* this rule is for when you specifically request this cursor class */
.rpgui-cursor-point,
.rpgui-cursor-point * {
  cursor: url("./assets/img/cursor/point.png") 10 0, auto !important; }

/* pointer / hand cursor, not important, for all elements that have pointer by-default */
.rpgui-content a,
.rpgui-content button,
.rpgui-button,
.rpgui-slider-container,
.rpgui-content input[type=radio].rpgui-radio + label,
.rpgui-list-imp,
.rpgui-dropdown-imp,
.rpgui-content input[type=checkbox].rpgui-checkbox + label {
  cursor: url("./assets/img/cursor/point.png") 10 0, auto; }

/* for input / text selection important */
/* this rule is for when you specifically request this cursor class */
.rpgui-cursor-select,
.rpgui-cursor-select * {
  cursor: url("./assets/img/cursor/select.png") 10 0, auto !important; }

/* for input / text selection, not important, for all elements that have pointer by-default */
.rpgui-cursor-select,
.rpgui-content input,
.rpgui-content textarea {
  cursor: url("./assets/img/cursor/select.png") 10 0, auto; }

/* for grabbing stuff */
/* this rule is for when you specifically request this cursor class */
.rpgui-cursor-grab-open,
.rpgui-cursor-grab-open * {
  cursor: url("./assets/img/cursor/grab-open.png") 10 0, auto !important; }

/* for grabbing stuff */
/* this rule is for when you specifically request this cursor class */
.rpgui-cursor-grab-close,
.rpgui-cursor-grab-close * {
  cursor: url("./assets/img/cursor/grab-close.png") 10 0, auto !important; }

/**
* Customized dropdown with rpgui design.
*/
/* dropdown box implemented with list (see rpgui-dropdown.js for details) */
/* note! this class rule affect both the dropdown header and the list elements! */
.rpgui-dropdown-imp,
.rpgui-dropdown {
  /* font */
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  color: white;
  /* default size */
  min-height: 40px;
  margin-top: 0px;
  /* border */
  border-style: solid;
  border-width: 7px 7px 7px 7px;
  -moz-border-image: url("./assets/img/select-border-image.png") 10% repeat repeat;
  -webkit-border-image: url("./assets/img/select-border-image.png") 10% repeat repeat;
  -o-border-image: url("./assets/img/select-border-image.png") 10% repeat repeat;
  border-image: url("./assets/img/select-border-image.png") 10% repeat repeat;
  /* background */
  background: url("./assets/img/select-background-image.png") repeat repeat;
  background-clip: padding-box;
  background-origin: padding-box;
  background-position: center; }

/* dropdown options list */
ul.rpgui-dropdown-imp {
  padding: 0 0 0 0 !important;
  z-index: 100; }

/* note! this affect only the dropdown header */
/* shows the currently selected value from select element */
.rpgui-content .rpgui-dropdown-imp-header {
  color: white !important;
  min-height: 22px !important;
  padding: 5px 10px 0 10px !important;
  margin: 0 0 0 0 !important;
  position: relative !important; }

/* dropdown options */
.rpgui-dropdown-imp li {
  /* font */
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  color: white;
  height: 16px;
  /* remove the dot */
  list-style-type: none;
  /* padding */
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
  /* background */
  background: url("./assets/img/select-background-image.png") repeat repeat;
  background-clip: padding-box;
  background-origin: padding-box;
  background-position: center; }

/* dropdown options hover */
.rpgui-dropdown-imp li:hover {
  color: #d27d2c; }

/* dropdown hover */
.rpgui-dropdown-imp:hover {
  color: #d27d2c; }

/**
* hr styling
*/
/* rpgui hr */
.rpgui-content hr {
  display: block;
  border: 0px;
  height: 10px;
  background: url("./assets/img/hr.png") repeat-x top left; }

/* rpgui golden hr */
.rpgui-content hr.golden {
  display: block;
  border: 0px;
  height: 10px;
  background: url("./assets/img/hr-golden.png") no-repeat top left;
  background-size: 100% 100%; }

/**
* Icon styles.
*/

.rpgui-content input,
.rpgui-content textarea {
  /* set size and colors */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: white;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  font-size: 0.9rem;
  line-height: 32px;
  background: #4e4a4e;
  max-width: 100%;
  width: 100%;
  padding-left: 10px;
  /* for ie */
  min-height: 30px;
  /* enable text selecting */
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.5); }

/* textarea extra rules */
.rpgui-content textarea {
  line-height: 22px;
  padding-top: 7px;
  height: 80px;
  resize: none; }

/* selection highlight */
.rpgui-content input::selection,
.rpgui-content textarea::selection {
  background: rgba(0, 0, 0, 0.5); }

.rpgui-content input::-moz-selection,
.rpgui-content textarea::-moz-selection {
  background: rgba(0, 0, 0, 0.5); }

/* dropdown box implemented with list (see rpgui-dropdown.js for details) */
/* note! this class rule affect both the dropdown header and the list elements! */
.rpgui-list-imp {
  /* font */
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  color: white;
  /* default size */
  min-height: 40px;
  margin-top: 0px;
  /* scrollers */
  overflow-x: hidden;
  overflow-y: scroll;
  /* border */
  border-style: solid;
  border-width: 7px 7px 7px 7px;
  -moz-border-image: url("./assets/img/select-border-image.png") 10% repeat repeat;
  -webkit-border-image: url("./assets/img/select-border-image.png") 10% repeat repeat;
  -o-border-image: url("./assets/img/select-border-image.png") 10% repeat repeat;
  border-image: url("./assets/img/select-border-image.png") 10% repeat repeat;
  /* background */
  background: url("./assets/img/select-background-image.png") repeat repeat;
  background-clip: padding-box;
  background-origin: padding-box;
  background-position: center; }

/* dropdown options list */
ul.rpgui-list-imp {
  padding: 0 0 0 0 !important;
  z-index: 100; }

/* dropdown options */
.rpgui-list-imp li {
  /* font */
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  color: white;
  height: 16px;
  margin-left: 5px !important;
  /* remove the dot */
  list-style-type: none;
  /* padding */
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
  /* background */
  background: url("./assets/img/select-background-image.png") repeat repeat;
  background-clip: padding-box;
  background-origin: padding-box;
  background-position: center; }

/* list options hover */
.rpgui-list-imp li:hover {
  color: #d27d2c; }

/* list hover */
.rpgui-list-imp:hover {
  color: #d27d2c; }

.rpgui-list-imp .rpgui-selected {
  background: rgba(0, 0, 0, 0.3); }

/**
* Paragraphs and headers while inside an rpgui container.
*/
/* default gui header */
.rpgui-content h1 {
  /* color and border */
  color: white;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  font-size: 1.14rem;
  /* center text */
  text-align: center;
  /* padding */
  padding: 0 0 0 0;
  margin: 7px 7px 17px 7px; }

/* default gui header2 */
.rpgui-content h2 {
  /* color and border */
  color: white;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  font-size: 1.25rem;
  /* center text */
  text-align: center;
  /* padding */
  padding: 0 0 0 0;
  margin: 7px 7px 17px 7px; }

/* default gui header3 */
.rpgui-content h3 {
  /* color and border */
  color: white;
  font-weight: 1;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  font-size: 1.15rem;
  text-decoration: underline;
  /* center text */
  text-align: center;
  /* padding */
  padding: 0 0 0 0;
  margin: 7px 7px 17px 7px; }

/* default gui header4 */
.rpgui-content h4 {
  /* color and border */
  color: white;
  font-weight: 1;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  font-size: 1.0rem;
  text-decoration: underline;
  /* center text */
  text-align: center;
  /* padding */
  padding: 0 0 0 0;
  margin: 7px 7px 17px 7px; }

/* default p */
.rpgui-content p {
  /* color and border */
  color: white;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  font-size: 1.0rem;
  line-height: 1.4; }

/* default span */
.rpgui-content span {
  /* color and border */
  color: white;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  font-size: 1.0rem;
  line-height: 1.4; }

/* default gui link */
.rpgui-content a {
  /* color and border */
  color: #d27d2c;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  font-size: 1.0rem;
  line-height: 1.4;
  text-decoration: none; }

/* default gui link */
.rpgui-content a:hover {
  text-decoration: underline; }

/* default gui label */
.rpgui-content label {
  /* color and border */
  color: white;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  font-size: 1.0rem;
  line-height: 1.4;
  display: inline; }

/* default gui label */
.rpgui-content li {
  /* color and border */
  margin-left: 20px;
  color: white;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  font-size: 1.0rem;
  line-height: 1.4; }

/*
* progress bar styling
*/
/* progress bar container */
.rpgui-progress {
  height: 42px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative; }

/* progress bar left edge */
.rpgui-progress-left-edge {
  position: absolute;
  height: 42px;
  width: 40px;
  left: 0px;
  background-image: url("./assets/img/progress-bar-left.png");
  background-size: 100% 100%; }

/* progress bar right edge */
.rpgui-progress-right-edge {
  position: absolute;
  height: 42px;
  width: 40px;
  right: 0px;
  background-image: url("./assets/img/progress-bar-right.png");
  background-size: 100% 100%; }

/* progress bar background track */
.rpgui-progress-track {
  position: absolute;
  height: 42px;
  left: 40px;
  right: 40px;
  background-image: url("./assets/img/progress-bar-track.png");
  background-repeat: repeat-x;
  background-size: 36px 100%; }

/* progress bar - the fill itself */
.rpgui-progress-fill {
  position: absolute;
  top: 9px;
  bottom: 8px;
  left: 0;
  width: 100%;
  background-image: url("./assets/img/progress.png");
  background-repeat: repeat-x;
  background-size: 36px 100%; }

/* progress bar - blue color */
.rpgui-progress-fill.blue {
  background-image: url("./assets/img/progress-blue.png"); }

/* progress bar - green color */
.rpgui-progress-fill.green {
  background-image: url("./assets/img/progress-green.png"); }

/* progress bar - red color */
.rpgui-progress-fill.red {
  background-image: url("./assets/img/progress-red.png"); }

/**
* style for radioes
*/
/* radio box */
.rpgui-content input[type=radio].rpgui-radio {
  display: none; }

.rpgui-content input[type=radio].rpgui-radio + label {
  background: url("./assets/img/radio-off.png") no-repeat;
  line-height: 24px;
  display: inline-block;
  background-size: auto 100%;
  padding-left: 34px;
  height: 24px;
  margin-top: 8px;
  margin-bottom: 8px; }

.rpgui-content input[type=radio].rpgui-radio:checked + label {
  background: url("./assets/img/radio-on.png") no-repeat;
  line-height: 24px;
  display: inline-block;
  background-size: auto 100%;
  padding-left: 34px;
  height: 24px; }

/* golden radio */
.rpgui-content .rpgui-radio.golden + label {
  background: url("./assets/img/radio-golden-off.png") no-repeat !important;
  background-size: auto 100% !important; }

.rpgui-content .rpgui-radio.golden:checked + label {
  background: url("./assets/img/radio-golden-on.png") no-repeat !important;
  background-size: auto 100% !important; }

/**
* Rules for misc and general things.
*/
/* set scrollbars for webkit browsers (like chrome) */
// .rpgui-content ::-webkit-scrollbar,
// .rpgui-content::-webkit-scrollbar {
//   width: 18px; }

/* Track */
// .rpgui-content ::-webkit-scrollbar-track,
// .rpgui-content::-webkit-scrollbar-track {
//   // background-image: url("./assets/img/scrollbar-track.png");
//   background-size: 18px 60px;
//   background-repeat: repeat-y; }

/* Handle */
// .rpgui-content ::-webkit-scrollbar-thumb,
// .rpgui-content::-webkit-scrollbar-thumb {
//   // background-image: url("./assets/img/scrollbar-thumb.png");
//   background-size: 100% 100%;
//   background-repeat: no-repeat; }

/* buttons */
// .rpgui-content ::-webkit-scrollbar-button,
// .rpgui-content::-webkit-scrollbar-button {
//   // background-image: url("./assets/img/scrollbar-button.png");
//   background-size: 100% 100%;
//   background-repeat: no-repeat; }

/**
* for disabled elements
*/
/* disabled object */
.rpgui-disabled,
.rpgui-content :disabled,
.rpgui-content input[type=radio]:disabled + label,
.rpgui-content input[type=checkbox]:disabled + label,
.rpgui-content input[type=range]:disabled + .rpgui-slider-container,
.rpgui-content :disabled + .rpgui-dropdown-imp,
.rpgui-content :disabled + .rpgui-dropdown-imp + .rpgui-dropdown-imp,
.rpgui-content :disabled + .rpgui-list-imp {
  cursor: url("./assets/img/cursor/default.png"), auto;
  -webkit-filter: grayscale(1);
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: url(#greyscale);
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: gray;
  color: #999; }

/**
* Rules for the slider.
*/
/* regular slider stuff */
/* slider container */
.rpgui-slider-container {
  height: 20px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative; }

/* slider left edge */
.rpgui-slider-left-edge {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 0px;
  background-image: url("./assets/img/slider-left.png");
  background-size: 100% 100%; }

/* slider right edge */
.rpgui-slider-right-edge {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 0px;
  background-image: url("./assets/img/slider-right.png");
  background-size: 100% 100%; }

/* slider background track */
.rpgui-slider-track {
  position: absolute;
  height: 20px;
  left: 0;
  right: 0;
  background-image: url("./assets/img/slider-track.png");
  background-repeat: repeat-x;
  background-size: 24px 100%; }

/* the part of the slider that moves and indicates the value */
.rpgui-slider-thumb {
  position: absolute;
  height: 30px;
  width: 15px;
  margin-top: -5px;
  left: 40px;
  background-image: url("./assets/img/slider-thumb.png");
  background-size: 100% 100%; }

/* golden slider stuff */
/* golden slider container */
.rpgui-slider-container.golden {
  height: 30px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative; }

/* golden slider left edge */
.rpgui-slider-left-edge.golden {
  position: absolute;
  height: 30px;
  width: 30px;
  left: 0px;
  background-image: url("./assets/img/slider-left-golden.png");
  background-size: 100% 100%; }

/* golden slider right edge */
.rpgui-slider-right-edge.golden {
  position: absolute;
  height: 30px;
  width: 30px;
  right: 0px;
  background-image: url("./assets/img/slider-right-golden.png");
  background-size: 100% 100%; }

/* golden slider background track */
.rpgui-slider-track.golden {
  position: absolute;
  height: 30px;
  left: 0;
  right: 0;
  background-image: url("./assets/img/slider-track-golden.png");
  background-repeat: repeat-x;
  background-size: 40px 100%; }

/* golden the part of the slider that moves and indicates the value */
.rpgui-slider-thumb.golden {
  position: absolute;
  height: 36px;
  width: 18px;
  margin-top: -4px;
  left: 40px;
  background-image: url("./assets/img/slider-thumb-golden.png");
  background-size: 100% 100%; }
</style>
